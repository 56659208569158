<template>
  <div>
    <top-nav></top-nav>
    <div class="rwa-cont">
      <div v-for="(item,index) in  packageList" :key="index">
        <!--        {{ item }}-->
        <div class="rwa-item-right" v-if="index%2 == 1">
          <div class="rwa-info">
            <div class="tr-title">{{ item.title }}</div>
            <div class="species-value">{{ item.val }}{{$t('种草值')}}</div>
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="multiple">
            x{{ item.multiple }}
          </div>
          <img class="rwa-bg" src="../../assets/image/rwa/bg2.png">
        </div>

        <div class="rwa-item-bottom" v-else-if="index >0 && index%4 == 0">
          <div class="rwa-info">
            <div class="tr-title">{{ item.title }}</div>
            <div class="species-value">{{ item.val }}{{$t('种草值')}}</div>
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="multiple">
            x{{ item.multiple }}
          </div>
          <img class="rwa-bg" src="../../assets/image/rwa/bg5.png">
        </div>
        <div class="rwa-item-left" v-else>
          <div class="rwa-info">
            <div class="tr-title">{{ item.title }}</div>
            <div class="species-value">{{ item.val }}{{$t('种草值')}}</div>
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="multiple">
            x{{ item.multiple }}
          </div>
          <img class="rwa-bg" src="../../assets/image/rwa/bg1.png">
        </div>
      </div>

      <!--      <div class="rwa-item-left">-->
      <!--        <div class="rwa-info">-->
      <!--          <div class="tr-title">北欧</div>-->
      <!--          <div class="species-value">100种草值</div>-->
      <!--          <div class="title">北欧</div>-->
      <!--        </div>-->
      <!--        <div class="multiple">-->
      <!--          x3-->
      <!--        </div>-->
      <!--        <img class="rwa-bg" src="../../assets/image/rwa/bg1.png">-->
      <!--      </div>-->
      <!--      <div class="rwa-item-right">-->
      <!--        <div class="rwa-info">-->
      <!--          <div class="tr-title">东南亚</div>-->
      <!--          <div class="species-value">100种草值</div>-->
      <!--          <div class="title">东南亚</div>-->
      <!--        </div>-->
      <!--        <div class="multiple">-->
      <!--          x2.5-->
      <!--        </div>-->
      <!--        <img class="rwa-bg" src="../../assets/image/rwa/bg2.png">-->
      <!--      </div>-->
      <!--      <div class="rwa-item-left">-->
      <!--        <div class="rwa-info">-->
      <!--          <div class="tr-title">中欧</div>-->
      <!--          <div class="species-value">100种草值</div>-->
      <!--          <div class="title">中欧</div>-->
      <!--        </div>-->
      <!--        <div class="multiple">-->
      <!--          x3-->
      <!--        </div>-->
      <!--        <img class="rwa-bg" src="../../assets/image/rwa/bg3.png">-->
      <!--      </div>-->
      <!--      <div class="rwa-item-right">-->
      <!--        <div class="rwa-info">-->
      <!--          <div class="tr-title">西欧</div>-->
      <!--          <div class="species-value">100种草值</div>-->
      <!--          <div class="title">西欧</div>-->
      <!--        </div>-->
      <!--        <div class="multiple">-->
      <!--          x3-->
      <!--        </div>-->
      <!--        <img class="rwa-bg" src="../../assets/image/rwa/bg4.png">-->
      <!--      </div>-->

      <!--      <div class="rwa-item-bottom">-->
      <!--        <div class="rwa-info">-->
      <!--          <div class="tr-title">拉丁美洲</div>-->
      <!--          <div class="species-value">100种草值</div>-->
      <!--          <div class="title">拉丁美洲</div>-->
      <!--        </div>-->
      <!--        <div class="multiple">-->
      <!--          x3-->
      <!--        </div>-->
      <!--        <img class="rwa-bg" src="../../assets/image/rwa/bg5.png">-->
      <!--      </div>-->

    </div>

    <div class="bottom-operate">
      <div class="add-but" @click="onShowpurchasePopup">
        {{$t('加入')}}
      </div>
      <div class="add-but" v-if="userInfo.is_activate == 1" @click="onShowRepeatePopup">
        {{$t('复投')}}
      </div>
    </div>
    <van-popup v-model="repeatePopup" position="bottom">
      <div class="purchase-popup" v-if="info">
        <div class="title">
          {{$t('欢迎获得西欧特权')}}
          <img @click="repeatePopup=false" class="close-icon" src="../../assets/image/rwa/close-icon.png">
        </div>

        <div class="pay-cont">
          <div class="name"> {{$t('支付')}}</div>
          <div class="pay-option">
            <div class="balance">
              <p class="balance-num"> {{$t('余额')}} : {{ info.forage }} {{$t('种草值')}}</p>
              <van-field class="number"
                         v-model="formData.forage"
                         :placeholder="$t('请输入')"
                         type="number"
              />
            </div>
            <div class="add-icon">
              <img src="../../assets/image/rwa/add-icon.png">
            </div>
            <div class="input-number">
              <p class="balance-num">{{$t('拔草值')}} : {{ info.cny }}</p>
              <van-field class="number"
                         v-model="formData.cny"
                         :placeholder="$t('请输入')"
                         type="number"
              />
            </div>
          </div>
        </div>

        <div class="bottom-cont">
          <div class="button-cont">
            <div class="order-but">
              <router-link :to="'/rwa/record/'+type">
                <img class="order-icon" src="../../assets/image/rwa/order-icon.png"/>
              </router-link>

            </div>
            <div class="confirm-but" @click="purchasePackage">{{$t('确定')}}</div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="purchasePopup" position="bottom">
      <div class="purchase-popup" v-if="info">
        <div class="title">
          {{$t('欢迎获得西欧特权')}}
          <img @click="purchasePopup=false" class="close-icon" src="../../assets/image/rwa/close-icon.png">
        </div>
        <div class="type-cont">
          <div class="type-item" @click="selectType('package')" :class="{'type-activity':type=='package'}">
            {{$t('套餐')}}
          </div>
          <div class="type-item" @click="selectType('cdk')" :class="{'type-activity':type=='cdk'}">
            CDK
          </div>
        </div>
        <div class="prompt">
          {{$t('购买套餐将直接获得算力,购买CDK将获得激活码用于激活， 不会获得算力。')}}
        </div>

        <div class="product-cont">
          <div class="name">产品</div>
          <div class="product-item" v-for="(item,index) in  packageList" :key="index"
               :class="[{'activity' : item.id == formData.package_id},{'disable':(item.disable && type == 'package')}]"
               @click="selectPackage(item)"
          >
            {{ item.title }} {{ item.val }} {{$t('种草值')}} x{{ item.multiple }}
          </div>
          <!--          <div class="product-item">-->
          <!--            北欧 100种草值 x2-->
          <!--          </div>-->
        </div>

        <div class="pay-cont">
          <div class="name">{{$t('支付')}}</div>
          <div class="pay-option">
            <div class="balance">
              <p class="balance-num">{{$t('余额')}} : {{ info.forage }} {{$t('种草值')}}</p>
              <van-field class="number"
                         v-model="formData.forage"
                         :placeholder="$t('请输入')"
                         type="number"
              />
            </div>
            <div class="add-icon">
              <img src="../../assets/image/rwa/add-icon.png">
            </div>
            <div class="input-number">
              <p class="balance-num">{{$t('拔草值')}} : {{ info.cny }}</p>
              <van-field class="number"
                         v-model="formData.cny"
                         :placeholder="$t('请输入')"
                         type="number"
              />
            </div>
          </div>
        </div>

        <div class="bottom-cont">
          <div class="available-num" v-if="available">
            <img class="amount-icon" src="../../assets/image/rwa/amount-icon.png">
            {{$t('可获得')}} <span>{{ available }}</span>{{$t('养草植')}}
          </div>
          <div class="button-cont">
            <div class="order-but">
              <router-link :to="'/rwa/record/'+type">
                <img class="order-icon" src="../../assets/image/rwa/order-icon.png"/>
              </router-link>

            </div>
            <div class="confirm-but" @click="purchasePackage">{{$t('确定')}}</div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="successPopup" @close="onCloseSuccessPopup">
      <div class="success-popup" v-if="successInfo">
        <img class="top-icon" src="../../assets/image/popup/top_icon.png">
        <div class="multiple">x{{ successInfo.multiple }}</div>
        <div class="title" v-if="successInfo.type =='package'">
          {{$t('恭喜获得')}}
        </div>
        <div class="title" v-else>
          {{$t('恭喜获得')}}{{ successInfo.title }}CDK
        </div>
        <div class="info" v-if="successInfo.type =='package'">{{ successInfo.power }} {{$t('养草值')}}</div>
        <div class="info" v-else>{{ successInfo.cdk_code }}</div>

        <div class="bg-but" v-if="successInfo.type =='cdk'" @click="copyData(successInfo.cdk_code)">
          {{$t('复制')}}
        </div>
        <div class="line-but" @click="onCloseSuccessPopup">
          {{$t('确定')}}
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {getPackageList, postPackage, buyCdk} from "../../api";
import {mapState} from "vuex";

export default {
  name: "index",
  data() {
    return {
      packageList: [],
      info: null,
      type: 'package', //package 套餐 cdk
      repeatePopup: false,
      purchasePopup: false,
      successPopup: false,
      number: '',
      formData: {
        "package_id": '',
        "forage": '',
        "cny": ''
      },
      selectCdk: null,
      available: 0,
      successInfo: null,
    }
  },
  components: {TopNav},
  created() {
    this.getUserInfo()
    this.getPackageList()
  },
  mounted() {

  },
  computed: {
    ...mapState({
      userInfo: state => state.app.userInfo,
    })
  },
  methods: {
    getUserInfo() {
      let that = this;
      this.$store.dispatch('app/getUserInfo')
          .then((data) => {
            console.log(data);
            if (data.code == 200) {
              if (data.data.d_pid == 0) {
                that.$router.push({
                  path: '/rwa/binding'
                })
              }
            }

          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    onShowRepeatePopup() {
      this.repeatePopup = true
      this.formData = {
        "package_id": 0,
        "forage": '',
        "cny": ''
      }
    },
    onShowpurchasePopup() {
      this.purchasePopup = true
      this.type = 'package'
      this.formData = {
        "package_id": '',
        "forage": '',
        "cny": ''
      }
      this.available = ''
    },
    onShowSuccessPopup(successInfo) {
      console.log(successInfo);
      this.purchasePopup = false
      this.repeatePopup = false
      this.successInfo = successInfo
      this.successPopup = true

      this.getPackageList()
    },
    onCloseSuccessPopup() {
      this.successInfo = null
      this.successPopup = false
    },
    purchasePackage() {
      let that = this
      if (that.formData.package_i == '') {
        that.$toast(that.$t('请选择产品'));
        return;
      } else if (!that.formData.forage) {
        that.$toast(that.$t('请输入正确种草值'));
        return;
      }

      if (that.type == 'package') {
        this.$toast.loading({
          message: '',
          duration: 0,
        });
        postPackage(that.formData)
            .then(({data}) => {
              console.log(data);
              if (data.code == 200) {
                let successInfo = {
                  type: 'package',
                  multiple: data.data.multiple,
                  power: data.data.power
                }
                that.onShowSuccessPopup(successInfo)
              } else {
                setTimeout(() => {
                  that.$toast(data.message);
                }, 500)
              }
            })
            .finally(() => {
              that.$toast.clear()
            })
      } else {
        this.$toast.loading({
          message: '',
          duration: 0,
        });
        buyCdk(that.formData)
            .then(({data}) => {
              console.log(data);
              if (data.code == 200) {
                let successInfo = {
                  type: 'cdk',
                  multiple: that.selectCdk.multiple,
                  title: that.selectCdk.title,
                  cdk_code: data.data.cdk_code,
                }
                that.onShowSuccessPopup(successInfo)
              } else {
                setTimeout(() => {
                  that.$toast(data.message);
                }, 500)
              }
            })
            .finally(() => {
              that.$toast.clear()
            })
      }
    },
    selectType(type) {
      this.type = type;
      this.formData = {
        "package_id": '',
        "forage": '',
        "cny": ''
      }
      this.available = ''
    },
    selectPackage(item) {
      if (item.disable && this.type == 'package') {
        return;
      } else if (this.type == 'cdk') {
        this.selectCdk = item
      } else {
        this.selectCdk = null
      }
      this.formData.package_id = item.id
      this.available = item.power


    },
    getPackageList() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      getPackageList()
          .then(({data}) => {
            console.log(data);
            if (data.code === 200) {
              that.packageList = data.data.package_list
              that.info = data.data
            }
          })
          .finally(() => {
            this.$toast.clear()
          })
    },
    copyData(text) {
      let that = this;
      if (text) {
        this.$copyText(text).then(
            function (e) {
              that.$toast(that.$t('复制成功'));
            }
        );
      }
    },
  }
}
</script>

<style scoped lang="less">
.rwa-cont {
  overflow: hidden;
  padding: 1.39rem 4.5vw;
  margin-bottom: 4.5rem;

  .rwa-item-left {
    position: relative;
    float: left;
    width: 44vw;
    margin-bottom: 1.5vw;

  }

  .rwa-item-right {
    position: relative;
    float: right;
    width: 44vw;
    margin-bottom: 1.5vw;

  }

  .rwa-item-bottom {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 1.5vw;

    .rwa-info {
      position: absolute;
      top: 3vw;
      left: 4vw;
    }
  }

  .rwa-bg {
    width: 100%;
  }

  .rwa-info {
    position: absolute;
    top: 6vw;
    left: 4vw;

    .tr-title {
      font-size: 1.93rem;
      font-weight: bold;
      color: #E7BD71;
      opacity: 0.26;
    }

    .species-value {
      position: relative;
      top: -0.7rem;
      font-size: 1.14rem;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1.5rem;
    }

    .title {
      position: relative;
      top: -0.5rem;
      font-size: 1.07rem;
      color: #E7BD71;
    }

    .title::after {
      content: '';
      position: absolute;
      left: 0.2rem;
      bottom: -0.7rem;
      width: 1.07rem;
      height: 0.14rem;
      background: #E7BD71;
      border-radius: 0.05rem;
    }
  }

  .multiple {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    font-size: 1.93rem;
    font-weight: bold;
    color: #000000;
    opacity: 0.8;
    transform: rotate(45deg);
  }


}

.bottom-operate {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  //height: 5rem;
  padding: 1rem 0;
  display: flex;
  background: #151515;

  .add-but {
    flex: 1;
    //width: 93%;
    margin: auto 1rem;
    text-align: center;
    line-height: 3.14rem;
    font-size: 1.29rem;
    font-weight: 600;
    color: #000000;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.57rem;
  }
}

.purchase-popup {
  min-height: 50vh;
  max-height: 85vh;

  overflow-y: auto;
  background: #1B1B1B;
  padding: 1.3rem 1rem;
  border-radius: 1.07rem 1.07rem 0rem 0rem;

  .title {
    font-size: 1.14rem;
    color: #E7BD71;
  }

  .close-icon {
    float: right;
    width: 1rem;

  }

  .type-cont {
    margin-top: 1.8rem;
    background: #151515;
    border-radius: 1.46rem;
    padding: 0.36rem 0.43rem;
    box-sizing: border-box;

    .type-item {
      display: inline-block;
      text-align: center;
      width: 50%;
      line-height: 2.93rem;
      height: 2.93rem;
      font-size: 0.93rem;
      color: #E7BD71;
      background: #151515;
      border-radius: 5.93rem;
    }

    .type-activity {
      color: #000;
      background: #E7BD71;
      border-radius: 2.93rem;
    }
  }

  .product-cont {
    .name {
      font-size: 1rem;
      color: #B5904E;
      margin-bottom: 1rem;
    }

    .product-item {
      padding: 1rem;
      background: #101010;
      border: 1px solid transparent;
      border-radius: 0.71rem;
      font-size: 0.93rem;
      font-weight: 400;
      color: #E7BD71;
      margin-bottom: 0.7rem;
    }

    .activity {
      border: 1px solid #E7BD71;
    }

    .disable {
      opacity: 0.6;
    }
  }

  .pay-cont {
    padding-bottom: 7rem;

    .name {
      font-size: 1rem;
      color: #B5904E;
      margin-top: 1.3rem;
      margin-bottom: 1rem;
    }

    .pay-option {
      overflow: hidden;
      display: flex;
      align-items: center;

      .balance {
        float: left;
        width: 50%;
        padding: 1rem;
        box-sizing: border-box;
        min-height: 5.71rem;
        background: #101010;
        border-radius: 0.71rem;

        .number {
          padding-top: 1.2rem;
        }

        :deep(.van-cell) {
          background: #101010;
        }

        :deep(.van-field__control::-webkit-input-placeholder) {
          color: #6E6045;
          font-size: 1rem;
        }

      }

      .add-icon {
        float: left;
        width: 10%;
        text-align: center;

        img {
          width: 0.93rem;
        }
      }

      .input-number {
        float: right;
        width: 40%;
        padding: 1rem;
        box-sizing: border-box;
        min-height: 5.71rem;
        background: #101010;
        border-radius: 0.71rem;

        .number {
          padding-top: 1.2rem;
        }

        :deep(.van-cell) {
          background: #101010;
        }

        :deep(.van-field__control::-webkit-input-placeholder) {
          color: #6E6045;
          font-size: 1rem;
        }
      }

      .quantity {
        padding-top: 1rem;
        font-size: 1rem;
        color: #FFFFFF;
      }

      .balance-num {
        font-size: 0.79rem;
        color: #6E6045;
      }

    }
  }

  .prompt {
    padding: 1.38rem 0.5rem;
    font-size: 0.86rem;
    color: #625F5F;
    line-height: 1.5rem;
  }

  .bottom-cont {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1.25rem 1rem 0.89rem 1rem;
    box-sizing: border-box;
    background: #151515;

    .available-num {
      display: flex;
      align-items: center;
      justify-content: right;
      line-height: 1.5rem;
      color: #E7BD71;
      font-size: 0.93rem;
      margin-bottom: 1.1rem;

      span {
        font-size: 1.1rem;
        padding-left: 2rem;
        padding-right: 0.3rem;
      }

      .amount-icon {
        width: 1rem;
      }
    }

    .button-cont {
      overflow: hidden;
      color: #000;

      .order-but {
        float: left;
        display: flex;
        width: 20%;
        height: 3.07rem;
        align-items: center;
        justify-content: center;
        background: #40341D;
        border-radius: 1.54rem;

        .order-icon {
          width: 1.5rem;
        }
      }

      .confirm-but {
        float: right;
        text-align: center;
        width: 75%;
        height: 3.07rem;
        font-size: 1.29rem;
        font-weight: 600;
        line-height: 3.07rem;
        background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
        border-radius: 1.54rem;
      }
    }
  }
}


.success-popup {
  position: relative;
  width: 75vw;
  margin-top: 6.5rem;
  padding-bottom: 2rem;
  background: url("../../assets/image/popup/bg.png") no-repeat;
  background-size: 100%;
  background-color: #2D2D2D;
  border-radius: 1.07rem;

  .top-icon {
    position: absolute;
    height: 6.5rem;
    top: -6.5rem;
    left: 0;
    right: 0;
    margin: auto;
  }

  .multiple {
    padding-top: 0.5rem;
    padding-right: 3vw;
    text-align: right;
    font-size: 1.93rem;
    font-weight: bold;
    color: #000000;
  }

  .title {
    width: 80%;
    margin: auto;
    padding-top: 3.5rem;
    text-align: center;
    font-size: 1.14rem;
    font-weight: bold;
    color: #E7BD71;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .info {
    width: 80%;
    margin: auto;
    padding-top: 1.3rem;
    margin-bottom: 2.3rem;
    text-align: center;
    font-size: 1.14rem;
    color: #E7BD71;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bg-but {
    margin: 1rem auto;
    text-align: center;
    width: 80%;
    font-size: 1.14rem;
    color: #000000;
    line-height: 2.86rem;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.43rem;
  }

  .line-but {
    margin: 1rem auto;
    text-align: center;
    width: 80%;
    font-size: 1.14rem;
    color: #E7BD71;
    line-height: 2.86rem;
    border: 1px solid #E7BD71;
    box-sizing: border-box;
    border-radius: 1.43rem;
  }
}
</style>